import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserFormSubmission } from 'src/@omnial/_models/user-forms/user-form-submission';
import { UserForm } from 'src/@omnial/_models/user-forms/user-form.model';
import { RepositoryService } from '../repository.service';

@Injectable()
export class UserFormsService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private repoService: RepositoryService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

  public getUserFormById(formId: number): Observable<UserForm> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.getData(`UserForms/${formId}`).subscribe({
        next: (res) => {
          observer.next(res as UserForm);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }

  public submitForm(submission: UserFormSubmission): Observable<UserFormSubmission> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoService.create('UserForms/Submission', submission).subscribe({
        next: (res) => {
          observer.next(res as UserFormSubmission);
          observer.complete();
        },
        error: (e) => {
          observer.error(e);
          observer.complete();
        }
      }));
    });
  }
}
